<template>
  <div class="menber-dialog">
    <el-dialog
      title="文章发布 "
      :visible.sync="visible"
      :modal="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="handleClose"
      width="1338px"
      top="24px"
    >
      <div class="form">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          :rules="formRules"
        >
          <el-form-item label="一级分类">
            <el-select v-model="catalogBef" style="width: 300px">
              <el-option
                v-for="(item, index) in types1"
                :key="index"
                :value="item.code"
                :label="item.remark"
              ></el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="二级分类">
            <el-select
              :disabled="!catalogBef"
              v-model="form.catalog"
              style="width: 300px"
            >
              <el-option
                v-for="(item, index) in types2"
                :key="index"
                :value="item.code"
                :label="item.remark"
              ></el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="文件标题" prop="title">
            <el-input v-model="form.title" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="关键字" prop="keywords">
            <el-tag
              :key="tag"
              v-for="tag in keywords"
              closable
              style="margin-right: 12px"
              type="success"
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              style="width: 100px"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              style="color: rgb(0, 160, 233)"
              size="small"
              @click="showInput"
              >新增关键字</el-button
            >
          </el-form-item>
          <el-form-item label="选择文件" prop="doc">
            <el-upload
              ref="upload"
              multiple
              action=""
              name="files"
              :file-list="fileList"
              :on-change="uploadCqpz"
              :on-remove="removeCqpz"
              :auto-upload="false"
            >
              <el-button type="info" size="small">选择文件</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="内容:"
            prop="content"
            style="width: 100% !important"
          >
            <!-- <mavon-editor
              v-model="form.content"
              :ishljs="true"
              ref="medit"
              @imgAdd="handleImageAdd"
            ></mavon-editor> -->

            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 500px; overflow-y: hidden"
                v-model="form.content"
                :defaultConfig="editorConfig"
                :mode="mode"
                ref="medit"
                @onCreated="onCreated"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取消</el-button>
        <el-button type="primary" v-if="type != 'view'" @click="submit"
          >发布</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { split } from "@/js/utils/util";
import { uploadPic, getRegionList } from "@/api/projectManage";
import { uploadFile, deleteFile } from "@/api/fileApi";
import { postNewsObj } from "@/api/add";
import { mapGetters } from "vuex";
import marked from "marked";

import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      baseUrl: window.globalUrl.BASE_API,
      form: {},
      keywords: [],
      catalogBef: "",
      catalogAft: "",
      type: "add",
      types1: [],
      types2: [],
      inputVisible: false,
      inputValue: "",
      content: "",
      xczpInfos: [],
      xczpIds: [],
      cqpzInfos: [],
      cqpzIds: [],
      regionOptions: [{}],
      props: {
        label: "xzqmc",
        children: "list",
        value: "xzqbh",
        checkStrictly: true,
      },
      fileList: [],
      files: [],
      formRules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        content: [{ required: true, message: "内容不为空", trigger: "blur" }],
      },

      // 富文本
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
    };
  },
  created() {},
  props: {
    visible: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    level1: {
      type: Array,
      default: function () {
        return [];
      },
    },
    level2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    catalogBef: function () {
      if (this.catalogBef) {
        this.$nextTick(() => {
          this.form.catalog = null;
          this.types2 = this.level2.filter((x) => {
            return x.code.slice(0, 4) == this.catalogBef;
          });
          this.form = { ...this.form };
        });
      }
    },
  },
  // components: {},
  computed: mapGetters(["userInfo", "identityObj"]),
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    // 富文本
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    // async handleImageAdd(editor, file) {
    //   let param = new FormData();
    //   param.append("catalog", "vip");
    //   param.append("file", file);
    //   let res = await uploadFile(param);
    //   if (res.code == 0) {
    //     let imageUrl = res.data.url;
    //     this.$refs.mdedit.$img2Url(
    //       editor,
    //       this.baseUrl + "admin/file/get?ossFilePath=" + imageUrl
    //     );
    //   }
    // },
    handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.keywords.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    async deleteFile(url) {
      let res = await deleteFile({ ossFilePath: url });
      if (res.code == 0 && res.data == true) {
        console.log("删除成功");
      }
    },
    removeCqpz(file) {
      let index = this.cqpzIds.indexOf(file.uid);
      this.deleteFile(this.cqpzInfos[index]);
      this.cqpzIds.splice(index, 1);
      this.cqpzInfos.splice(index, 1);
    },
    async uploadCqpz(file, filelist) {
      let res = await this.uploadFile(file);
     
    },
    async uploadFile(file, type) {
      let param = new FormData();
      param.append("catalog", "vip");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      if (res.code == 0) {
        this.cqpzIds.push(file.uid);
        this.cqpzInfos.push(
          JSON.stringify({
            name: res.data.sourceFileName,
            url: res.data.url,
          })
        );
      }
    },
    async submit() {
      console.log(this.identityObj);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            author: this.identityObj.identityName,
            title: this.form.title,
            keyword: this.keywords.join(","),
            catalog: this.form.catalog ? this.form.catalog : this.catalogBef,
            content: marked(this.form.content),
            attachFileUrl: this.cqpzInfos.join("?"),
            id: this.form.articleId ? this.form.articleId : null,
            delFlag: this.type == "edit" ? "0" : null,
          };
          console.log("params", params);
          let res = await postNewsObj(params);
          if (res.code == 0) {
            this.$message.success("发布成功");
            this.back();
          
          } else {
            this.$message.error("发布失败");
          }
        } else {
          this.$message.error("请完善发布信息");
        }
      });
    },
    back() {
      this.$emit("closeDialog", false);
      this.fileList=[]
    },
    handleClose(done) {
      done();
      this.$emit("closeDialog", false);
    },
    async getRegions() {
      // 获取地区信息
      let res = await getRegionList();
      let { status, data } = res;
      if (status) {
        let temp = data;
        this.regionOptions = split(temp);
      }
    },
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
/deep/.v-note-panel {
  max-height: 440px !important;
  overflow: auto;
}
/deep/.v-note-wrapper {
  height: 400px !important;
}
/deep/.el-dialog__body {
  padding-left: 90px;
  max-height: 750px;
  overflow: auto;
}
.sends-info {
  padding-bottom: 20px;
  text-align: left;
  .form {
    text-align: left;
    // width: 679px;
    margin: 0 auto;
    margin-top: 28px;
    padding: 0 150px 0px 50px;

    .el-radio-group {
      text-align: left;
    }

    .el-radio-button,
    .el-radio-button__inner {
      margin-right: 16px;
      width: 89px;
    }
  }

  .form-item-con {
    height: 50px;
  }
}
</style>
